/*iphone */
*, *::after, *::before {
  box-sizing: border-box;
}

:root {
  --ip-color-action: hsl(209, 45%, 30%);
  --color-brand: hsl(209, 45%, 30%);
  --ip-color-bg: hsl(209, 45%, 95%);
  --ip-color-text: hsl(209, 45%, 30%);
  --ip-color-header-text: white;
  --imi-danger: #B14250;
  --imi-success: #00C065;
}

.dewa-theme a, .dewa-theme button, .dewa-theme img, .dewa-theme input, .dewa-theme span {
  transition: none;
}
body {
  overflow-x: hidden;
}

button:focus,
button:visited,
button:active {
  outline: none;
}


.dewa-theme label {
  display: block;
}

.dewa-theme input, .dewa-theme button, .dewa-theme select, .dewa-theme textarea {
  font-family: inherit;
  font-size: inherit;
  padding: 0.4em;
  /*margin: 0 0 0.5em 0;*/
  box-sizing: border-box;
  border: 1px solid #ccc;
  background: white;
  border-radius: 2px;
}

.dewa-theme input:disabled {
  color: #ccc;
}

.dewa-theme button {
  outline: none;
}

.dewa-theme button:disabled {
  color: #999;
  opacity: 0.6;
  cursor: not-allowed;
}

.dewa-theme button:not(:disabled):active {
  background-color: #ddd;
}

.dewa-theme button:focus {
  border-color: #666;
}


.dewa-theme .page {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: radial-gradient(circle farthest-corner at 18.7% 37.8%, #fafafa 0%, #e1eaee 90%);
}

.dewa-theme .page__content {
  position: relative;
}

.dewa-theme .page__footer {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 15px 25px;
  z-index: 201;
  background-color: rgba(183, 155, 108, 0.3);
  color: #B79B6C;
  cursor: pointer;
  //transition: .3s ease all;
  will-change: background-color;
}

.dewa-theme .page__footer:hover {
  background-color: rgba(183, 155, 108, 0.5);
}

.phone {
  width: 290px;
  height: 100vh;
  max-height: 587px;
  background-color: black;
  border-radius: 45px;
  background-color: #0b3b8b;
  padding: 5px;
  position: relative;
  z-index: 2;
  transform-origin: center center;
  box-shadow: 0 35px 60px -24px rgba(0, 0, 0, 0.4);
}

.phone .message-wrapper-file {
  width: 225px !important;
}

.phone::before, .phone::after {
  content: '';
  height: 4px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 0;
}

.phone::before {
  top: 50px;
}

.phone::after {
  bottom: 50px;
}


.phone__view {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 36px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.4);
}

.phone__view-inner {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dewa-theme .content {
  font-weight: 300;
  line-height: 1.4;
  padding: 0 30px;
}

.dewa-theme .dot {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin: 0 7px;
  background-color: rgba(183, 155, 108, 0.25);
}

.dewa-theme .dots {
  width: 100px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
  position: absolute;
  top: -30px;
  right: -35px;
  opacity: 0;
  //animation: dotsAnime .5s ease .5s forwards;
}

.dewa-theme .waves {
  position: absolute;
  bottom: -25px;
  left: -35px;
  opacity: 0;
  //animation: waveAnime .5s ease .6s forwards;
}

.dewa-theme .st0 {
  display: none;
  fill: #5528C6;
  opacity: 0.2;
}

.dewa-theme .st1 {
  fill: none;
  stroke: #5528C6;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  opacity: 0.2;
}

.dewa-theme .st3 {
  display: inline;
  fill: none;
  stroke: #5528C6;
  stroke-miterlimit: 10;
  opacity: 0.2;
}

.st4 {
  display: inline;
  opacity: 0.2;
}

.st5 {
  fill: none;
  stroke: #5528C6;
  stroke-miterlimit: 10;
  opacity: 0.2;
}

.circle,
.square {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  opacity: 0;
  //animation: fadeIn .3s ease forwards 1s 1;
  pointer-events: none;
  wil-change: transform, opacity;
}

.dewa-theme .circle.bg-primary,
.dewa-theme .square.bg-primary {
  border: 20px solid #5867dd;
}

.dewa-theme .circle.bg-danger,
.dewa-theme .square.bg-danger {
  border: 20px solid #ff4651;
}

.circle.bg-success,
.square.bg-success {
  border: 20px solid #1dc9b7;
}

.circle.bg-warning,
.square.bg-warning {
  border: 20px solid #ffb822;
}

.circle.bg-info,
.square.bg-info {
  border: 20px solid #2786fb;
}

.circle.blurred,
.square.blurred {
  filter: blur(8px);
  z-index: 200;
}

.square {
  transform: rotate(30deg);
}

.circle {
  border-radius: 50%;
}

.dewa-theme .modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 320px;
  background-color: white;
  z-index: 205;
  display: flex;
  flex-direction: column;
  box-shadow: -8px 15px 50px rgba(0, 0, 0, 0.06);
  transform: translateX(100%);
  //transition: .6s ease all;
  will-change: transform;
}

.dewa-theme .modal.active {
  transform: translateX(0);
}

.dewa-theme .modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(183, 155, 108, 0.1);
  padding: 16px 12px 16px 24px;
}

.dewa-theme .modal__title {
  font-weight: 400;
  color: #B79B6C;
  font-size: 18px;
  margin: 0;
}

.dewa-theme .modal__close {
  background-color: transparent;
  border: none;
  width: 32px;
  height: 32px;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  //transition: .3s ease all;
  will-change: background-color;
}

.dewa-theme .modal__close:hover {
  background-color: rgba(183, 155, 108, 0.3);
}

.dewa-theme .modal__close::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  border-right: 1px solid rgba(0, 0, 0, 0.5);
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  top: 10px;
  left: 6px;
}

.dewa-theme .modal__content {
  flex: 1;
  padding: 30px;
  line-height: 1.7;
  color: #95989c;
}

.dewa-theme .modal__content .fa-laugh-wink {
  color: #ffb822;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes phoneAnime {
  0% {
    transform: rotate(45deg);
    opacity: 0;
  }
}

@keyframes dotsAnime {
  from {
    opacity: 0;
    transform: translate(-50%, 50%) scale(0.4);
  }
  to {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
}

@keyframes waveAnime {
  from {
    opacity: 0;
    transform: translateX(50%) scale(0.4);
  }
  to {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}

body::-webkit-scrollbar {
  display: none;
}

body .phone {
  -ms-overflow-style: none;
}

.phone {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.phone ::-webkit-scrollbar {
  width: 5px;
  height: 0;
}

.phone ::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 0;
}

.phone ::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.phone__body .carousal-container {
  min-width: 200px !important;
}

.carousal-container {
  margin-bottom: 5px;
  //animation: human-slide-right 0.5s;
  position: relative;
  -ms-overflow-style: none;
  overflow: auto;
  width: calc(100% - 10px);
  min-width: 230px;
}

.carousal-container.no-controls .control {
  display: none;
}

.carousal-container[data-itemToShow='1'] .item {
  width: 100%;
}

.carousal-container[data-itemToShow='2'] .item {
  width: 50%;
}

.carousal-container[data-itemToShow='3'] .item {
  width: 33.3333333%;
}

.carousal-container.hide-right-control .control-right {
  display: none;
}

.carousal-container .control {
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #e0e0e0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  width: 18px;
  height: 18px;
  transform: translateY(-50%);
  font-size: 13px;
  color: $color-brand;
  border-radius: 50%;
  cursor: pointer;
}

.carousal-container .control.control-left {
  left: -7px;
}

.carousal-container .control.control-right {
  right: -7px;
}

.phone__body .control-left {
  left: -9px;
}

.phone__body .control-right {
  right: -9px;
}

.carousal-container-inner {
  display: flex;
  //transition: all 0.5s;
}

.carousal-container-inner .item {
  width: 214px;
  background: white;
  flex-shrink: 0;
  overflow: hidden;
}

.bot-carousal-item {
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-right: 2px;
  margin-left: 2px;
  overflow: hidden;
}

.bot-carousal-item .banner {
  height: 0;
  padding: 28.125%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-position: center;
}

.bot-carousal-item .action * {
  color: #474747;
}

.bot-carousal-item .action *:hover {
  color: #2b5073;
  color: var(--ip-color-text);
}

.bot-carousal-item .action, .bot-carousal-item .title-wrapper {
  min-height: 32px;
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .bot-carousal-item .action, .bot-carousal-item .title-wrapper {
    /* IE10+ CSS1.1 here */
    height: 45px;
  }
}

.bot-carousal-item .action .title-text, .bot-carousal-item .title-wrapper .title-text {
  font-weight: 600;
  /*https://stackoverflow.com/questions/3922739/limit-text-length-to-n-lines-using-css*/
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.bot-carousal-item .action .description-text, .bot-carousal-item .title-wrapper .description-text {
  margin-bottom: 6px;
  margin-top: 6px;
  /*https://stackoverflow.com/questions/3922739/limit-text-length-to-n-lines-using-css*/
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.bot-carousal-item .action {
  padding: 0 !important;
}

.bot-carousal-item .action .link-wrapper {
  padding: 8px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .bot-carousal-item .action .link-wrapper {
    /* IE10+ CSS1.1 here */
    padding: 12px 23px !important;
  }
}

.bot-carousal-item .action {
  text-align: center;
  cursor: pointer;
  display: flex;
  /*limit oneline doesn't work om flex*/
}

.shadow-theme {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2) !important;
}

#myImg {
  border-radius: 5px;
  cursor: pointer;
  //transition: 0.3s;
}

#myImg:hover {
  opacity: 0.7;
}

/* The Modal (background) */
.dewa-theme .modal2 {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100vw;
  /* Full width */
  height: 100vh;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9);
  /* Black w/ opacity */
  z-index: 3;
  /* Modal Content (image) */
  /* Caption of Modal Image */
  /* Add //animation */
  /* The Close Button */
}

.dewa-theme .modal2 .modal-content {
  margin: auto;
  display: block;
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  max-height: 100%;
}

.dewa-theme .modal2 #caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

.dewa-theme .modal2 .modal-content, .modal2 #caption {
  //-webkit-animation-name: zoom;
  //-webkit-animation-duration: 0.6s;
  //animation-name: zoom;
  //animation-duration: .6s;
  //animation-fill-mode: forwards;
  transform-origin: 0;
}

.click-to-zoom {
  max-width: 357px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.time {
  font-size: 9px;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0) translate(-50%, -50%);
  }
  to {
    -webkit-transform: scale(1) translate(-50%, -50%);
  }
}

@keyframes zoom {
  from {
    transform: scale(0) translate(-50%, -50%);
  }
  to {
    transform: scale(1) translate(-50%, -50%);
  }
}

.dewa-theme .modal2 .close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  //transition: 0.3s;
}

.dewa-theme .modal2 .close:hover,
.dewa-theme .modal2 .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .dewa-theme .modal-content {
    width: 100%;
  }
}

/* The snackbar - position it at the top and in the middle of the screen */
#snackbar {
  min-width: 250px;
  background-color: indianred;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 10000;
  left: 80%;
  top: 30px;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar {
  visibility: visible;
  /* Show the snackbar */
  /* Add //animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  //-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  //animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* //animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}

@media only screen and (max-width: 700px) {
  #snackbar {
    width: 90% !important;
    left: 5%;
    margin-left: 0;
  }
}

.phone-notification #snackbar {
  left: 50% !important;
  max-width: 250px;
  transform: translateX(-50%);
}

:root {
  --primary: map-get($vars, primary);
}

body {
  margin: 0;
  padding: 0;
  font-size: 13px;
  height: 100%;
}

.imi-button-primary {
  width: 78px;
  height: 32px;
  border-radius: 2px;
  border: solid 1px #086b2e;
  background-color: white;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

.dewa-theme .d-flex {
  display: flex !important;
}

.dewa-theme .d-none {
  display: none !important;
}

.description {
  font-size: 12px;
}

.lang-rtl .content {
  text-align: right !important;
  direction: rtl;
}

.bg-opaque {
  background: rgba(0, 0, 0, 0.32) !important;
  opacity: 0.2;
}

.bg-opaque * {
  opacity: 0.5;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.dewa-theme .modal1 {
  display: none;
  width: 100%;
  height: 35vh;
  background: white;
  z-index: 1000000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  justify-content: center;
  align-items: center;
}

.dewa-theme .modal1 .fa {
  position: absolute;
  right: 25px;
  top: 25px;
  padding: 10px;
  cursor: pointer;
}

.dewa-theme .modal1 .lang-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dewa-theme .modal1 .lang-panel select {
  padding: 5px 10px;
  text-indent: 5px;
  box-sizing: content-box;
  width: 74px;
  margin: 20px 10px;
}

.dewa-theme .modal1 .lang-panel h1 {
  margin: 0;
  font-size: 18px;
  color: black;
}

.phone__view .imi-preview-grid-container {
  padding: 12px 0;
}

.phone__view .options {
  width: 40px;
  display: flex;
  justify-content: center;
  margin-left: 10px;
  margin-right: 0 !important;
}

.phone__view .bot-intro {
  text-align: center;
  margin-top: 14px;
}

.phone__view .header .title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.phone__view .link-preview-wrapper .limit-oneline {
  max-width: 140px;
}

.test-container {
  height: 100%;
  width: 100%;
}

.imi-preview-grid-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.imi-preview-grid-container .header {
  height: 60px;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: #2b4f70;
  background-color: $color-brand;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  color: white;
  color: var(--ip-color-header-text);
  justify-content: space-between;
}

.imi-preview-grid-container .header .bot-intro {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 10px;
}

.imi-preview-grid-container .header .bot-intro .title {
  font-weight: bold;
}

.imi-preview-grid-container .header .bot-intro .bot-logo {
  margin-right: 10px;
  flex-grow: 0;
}

.imi-preview-grid-container .header .bot-intro .bot-logo img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.imi-preview-grid-container .header .bot-intro .options {
  margin-right: 6px;
  font-size: 18px;
  cursor: pointer;
  padding: 8px;
}

.imi-preview-grid-container .header .bot-intro .options .refresh-button {
  font-size: 13px;
  opacity: .7;
}

.imi-preview-grid-container .header .bot-intro .options .refresh-button:hover {
  opacity: 1;
}

.imi-preview-grid-container .header .bot-intro .bot-details {
  flex-grow: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.imi-preview-grid-container .footer {
  height: 60px;
  flex-shrink: 0;
  flex-grow: 0;
  grid-area: footer;
  border-top: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 0 16px;
  position: relative;
}

.imi-preview-grid-container .footer #chat-input {
  flex-grow: 1;
  font-size: 13px;
  outline: none;
  border: none;
  color: #696969;
  padding: 5px;
}

.imi-preview-grid-container .footer .icon {
  font-size: 14px;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  background-color: #2b4f70;
  background-color: $color-brand;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
}

.imi-preview-grid-container .footer .icon .fa {
  //transform: rotate(45deg);
  //transition-duration: .3s;
  cursor: pointer;
  color: white;
  color: var(--ip-color-header-text);
}

.imi-preview-grid-container .footer .icon:hover .fa {
  transform: rotate(0);
}

.imi-preview-grid-container .loader {
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.imi-preview-grid-container .loader img {
  height: 100px;
}

.chat-body {
  color: #474747;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: flex-start;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  min-height: 382px;
  max-height: 382px;
  z-index: 0;
}

.chat-body .message-wrapper, .chat-body .time {
  //animation: human-slide-right 0.5s;
  //animation-fill-mode: forwards;
}

.chat-body .message-wrapper-quick-reply {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 8px;
  //animation: human-slide-right 0.5s;
  max-width: 90%;
}

.chat-body .message-wrapper-quick-reply .message-wrapper-quick-reply-button {
  border: 1px solid transparent;
  background-color: white;
  border-color: #2b5073;
  border-color: var(--ip-color-text);
  max-width: 100%;
  flex-shrink: 0;
  margin-right: 10px;
  min-height: 32px;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

@media (hover: hover) {
  .chat-body .message-wrapper-quick-reply .message-wrapper-quick-reply-button:hover {
    background-color: #edf2f8;
    background-color: var(--ip-color-bg);
  }
}

.chat-body.feedbackDisabled .msg-bubble-options-panel {
  display: none !important;
}

.chat-body .msg-bubble {
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
  width: 100%;
  max-width: 642px;
}

.chat-body .msg-bubble .msg-bubble-options-panel {
  //animation: human-slide-right 0.5s;
  margin-bottom: 8px;
  min-width: 100px;
  border-radius: 15px;
  z-index: 2;
  //transition: opacity .3s;
  display: flex;
  align-items: center;
}

.chat-body .msg-bubble .msg-bubble-options-panel .feedback {
  display: none !important;
}

.chat-body .msg-bubble .msg-bubble-options-panel.ask-feedback .feedback {
  display: flex !important;
}

.chat-body .msg-bubble .msg-bubble-options-panel.ask-feedback .feedback .fa {
  cursor: pointer;
}

.chat-body .msg-bubble .msg-bubble-options-panel.ask-feedback .ask-label {
  cursor: pointer;
}

.chat-body .msg-bubble .msg-bubble-options-panel.ask-feedback .final-label {
  display: none;
}

.chat-body .msg-bubble .msg-bubble-options-panel .feedback.active {
  display: flex !important;
}

.chat-body .msg-bubble .msg-bubble-options-panel .feedback.active .ask-label {
  display: none;
}

.chat-body .msg-bubble .msg-bubble-options-panel .feedback {
  display: flex;
  align-items: center;
}

.chat-body .msg-bubble .msg-bubble-options-panel .feedback:first-child {
  margin-right: 10px;
}

.chat-body .msg-bubble .msg-bubble-options-panel .fa {
  cursor: pointer;
  margin-right: 4px;
}

.chat-body .msg-bubble .msg-bubble-options-panel .fa-thumbs-up {
  color: #34bc6e;
}

.chat-body .msg-bubble .msg-bubble-options-panel .fa-thumbs-down {
  color: #d94254;
}

.chat-body .msg-bubble .msg-bubble-options {
  position: absolute;
  top: 0;
  line-height: 0;
  right: 10px;
  color: #808080a1;
  cursor: pointer;
  display: none;
  z-index: 2;
}

.chat-body .msg-bubble:hover .msg-bubble-options-panel {
  opacity: 1;
}

.chat-body .msg-bubble .message-wrapper .badge .inner-html-wrapper {
  display: flex;
  align-items: center;
}

.chat-body .msg-bubble .message-wrapper .content {
  position: relative;
  border-top-left-radius: 0 !important;
  background: white;
}

.chat-body .msg-bubble .message-wrapper .content::after {
  content: "";
  width: 9px;
  height: 15px;
  position: absolute;
  top: 0px;
  left: -6px;
  clip-path: polygon(0 0, 100% 100%, 100% 0);
  z-index: -14;
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .chat-body .msg-bubble .message-wrapper .content::after {
    /* IE10+ CSS1.1 here */
    display: none !important;
  }
}

@supports (-ms-ime-align: auto) {
  .chat-body .msg-bubble .message-wrapper .content::after {
    display: none !important;
  }
}

.chat-body .msg-bubble.msg-bubble-announcement {
  max-width: 100000px;
}

.chat-body .msg-bubble.msg-bubble-human {
  flex-direction: column-reverse;
  margin-left: auto;
}

.chat-body .msg-bubble.msg-bubble-human .message-container {
  align-items: flex-end;
  width: 100%;
}

.chat-body .msg-bubble.msg-bubble-human .msg-bot-logo {
  display: none !important;
}

.chat-body .msg-bubble.msg-bubble-human .message-wrapper .content {
  background-color: #edf2f8;
  background-color: var(--ip-color-bg);
  color: #2b5073;
  color: var(--ip-color-action);
  position: relative;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 4px !important;
}

.chat-body .msg-bubble.msg-bubble-human .message-wrapper .content::after {
  content: "";
  background-color: #edf2f8;
  background-color: var(--ip-color-bg);
  width: 9px;
  height: 15px;
  position: absolute;
  top: 0;
  left: unset;
  right: -6px;
  clip-path: polygon(0 0, 0% 100%, 100% 0) !important;
  z-index: -14;
  border-top-right-radius: 4px;
  border-top-left-radius: 0;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .chat-body .msg-bubble.msg-bubble-human .message-wrapper .content::after {
    /* IE10+ CSS2.1 here */
    display: none !important;
  }
}

@supports (-ms-ime-align: auto) {
  .chat-body .msg-bubble.msg-bubble-human .message-wrapper .content::after {
    display: none !important;
  }
}

.chat-body .msg-bubble.msg-bubble-human .message-wrapper, .chat-body .msg-bubble.msg-bubble-human .time {
  //animation: human-slide-left 0.5s;
  //animation-fill-mode: forwards;
}

.chat-body .msg-bubble.msg-bubble-human .time {
  margin-left: auto;
}

.chat-body .msg-bubble .msg-bot-logo {
  height: 40px;
  border-radius: 50%;
  width: 40px;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 10px;
}

.chat-body .msg-bubble .message-container {
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100%);
  /*10 px is for space bars in IE and FF*/
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .chat-body .msg-bubble .message-container {
    /* IE10+ CSS1.1 here */
  }
}

.chat-body .msg-bubble .message-container .message-wrapper {
  flex-shrink: 0;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .chat-body .msg-bubble .message-container .message-wrapper {
    /* IE10+ CSS1.1 here */
    word-break: break-word;
    max-width: 100%;
  }
}

.chat-body .msg-bubble .message-container .message-wrapper .content {
  padding: 8px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  word-break: break-word;
}

.chat-body .msg-bubble .message-container .message-wrapper audio {
  outline: none !important;
}

.chat-body .msg-bubble .message-container .message-wrapper .msg-img, .chat-body .msg-bubble .message-container .message-wrapper .msg-video {
  background: #eaeaea;
  box-sizing: content-box;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  outline: none !important;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .chat-body .msg-bubble .message-container .message-wrapper .msg-img, .chat-body .msg-bubble .message-container .message-wrapper .msg-video {
    /* IE10+ CSS1.1 here */
    height: 100%;
  }
}

.chat-body .msg-bubble .message-container .message-wrapper .msg-video {
  max-width: 90%;
  max-height: 200px;
  padding: 10px;
}

.chat-body .msg-bubble .message-container .message-wrapper-human {
  margin-left: auto;
}

.chat-body .msg-bubble .message-container .message-wrapper-human .content {
  color: #2b5073;
  color: var(--ip-color-text);
  box-shadow: none;
}

.dewa-theme {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 68px;
  right: 20px;
  z-index: 9999;
  width: 350px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 15px;
  box-shadow: 0px 15px 20px rgb(0 0 0 / 10%);
  visibility: hidden;
  overflow: hidden;
}

.dewa-theme.show-chat {
  //-moz-transition: bottom 0.5s linear;
  //-webkit-transition: bottom 0.5s linear;
  //transition: bottom 0.5s linear;
  visibility: visible;
  bottom: 100px;
}

.dewa-theme .imi-preview-grid-container {
  position: relative;
}

.dewa-theme .imi-preview-grid-container .basel-bg {
  background-color: #2b4f70;
  background-color: $color-brand;
  position: absolute;
  left: -100px;
  right: -100px;
  height: 72px;
  top: 0px;
  z-index: 0;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.15);
}

.dewa-theme .imi-preview-grid-container .bot-intro {
  z-index: 1;
  background-color: #2b4f70;
  background-color: $color-brand;
}

@keyframes human-slide-left {
  0% {
    opacity: 0;
    transform: translateX(1rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes human-slide-right {
  0% {
    opacity: 0;
    transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.imi-web {
  /*following style should not be applied in case of embed*/
}

@media only screen and (max-width: 600px) {
  .imi-web .imi-preview-grid-container {
    position: fixed !important;
    padding: 0 !important;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 100000;
    overflow: hidden;
    background: white;
  }
  .imi-web .imi-preview-grid-container .bot-intro {
    margin: 0 !important;
  }
  .imi-web .imi-preview-grid-container .basel-bg {
    display: none;
  }
  .imi-web .imi-preview-grid-container .header {
    padding: 0 10px !important;
    background-color: #2b4f70;
    background-color: $color-brand;
  }
  .imi-web body {
    font-size: 16px !important;
  }
}

.msg-shadow {
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
}

.chat-img-overlay {
  //transition: all 500ms;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100000;
  display: flex;
  background-color: rgba(99, 99, 99, 0.3);
  max-width: 100%;
}

@media screen and (max-width: 786px) {
  .chat-img-overlay {
    width: 100%;
    height: auto;
  }

  .dewa-theme {
    width: 100%;
    height: calc(100% - 100px);
    position: fixed;
    left: unset !important;
    bottom: 100px !important;
    right: unset !important;
    border-radius: 0;
    .chat-body {
      min-height: unset !important;
      max-height: unset !important;
      flex: 1;
    }
  }

  .chat-screen.dewa-theme {
    height: 100%;
    bottom: unset !important;
  }
}

.chat-img-overlay .chat-know-more-overlay {
  background-color: white;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 40px;
}

@media screen and (min-width: 786px) {
  .chat-img-overlay .chat-know-more-overlay {
    height: 100%;
    width: 30vw;
  }
}

.chat-img-overlay .chat-know-more-overlay .chat-know-more-overlay-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chat-img-overlay .chat-know-more-overlay .chat-know-more-overlay-header img {
  height: 55px;
}

.chat-img-overlay .chat-know-more-overlay .chat-know-more-overlay-header .description-bottom {
  max-width: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.chat-img-overlay .chat-know-more-overlay .chat-know-more-overlay-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.chat-img-overlay .chat-know-more-overlay .chat-know-more-overlay-item img {
  width: 65px;
  margin-right: 10px;
}

.close-chat-img-overlay {
  position: absolute;
  right: 2vw;
  top: 2vw;
  z-index: 10000;
  color: black !important;
  font-size: 18px;
  cursor: pointer;
}

@media screen and (max-width: 786px) {
  .close-chat-img-overlay {
    top: 25px;
    right: 25px;
  }
}

@media screen and (max-width: 786px) {
  .hide-mobile {
    display: none;
  }
}

.session-expiry-message {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.session-expiry-message:first-child {
  display: none;
}

.feedback-form-diabled .downvote-comment-submit {
  pointer-events: none;
  opacity: .5;
}

.feedback-form-diabled .form-error {
  display: none;
  font-size: 9px;
  color: red;
  margin-bottom: 6px;
}

.msg-bubble a {
  text-decoration: none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .msg-bubble a {
    /* IE10+ CSS1.1 here */
    display: block;
    word-break: break-all;
  }
}

.msg-bubble a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.msg-bubble-human a {
  color: #2b5073;
  color: var(--ip-color-text);
}

.link-wrapper {
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 13px;
}

.bot-link *, .bot-link .link-wrapper-link {
  color: #2b5073;
  color: var(--ip-color-text);
}
.bot-link .link-wrapper {
  padding: 8px 23px !important;
}

.bot-link *:hover, .bot-link .link-wrapper-link:hover {
  color: #2b5073;
  color: var(--ip-color-text);
}

#imi-chat-container {
  position: absolute;
  bottom: 104%;
  right: 0px;
  width: 330px;
  height: 80vh;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

#imi-chat-container body {
  font-size: 16px !important;
}

.imi-button-primary {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 78px;
  height: 32px;
  border-radius: 2px;
  border: 1px solid #086b2e;
  background-color: #fff;
  cursor: pointer;
  padding: 0;
}

/*embed*/
.imi-chat-toggle {
  position: fixed;
  bottom: 15px;
  right: 15px;
  height: 60px;
  width: 60px;
  background-color: #2b4f70;
  background-color: $color-brand;
  border-radius: 50%;
}

.imi-chat-toggle .imi-chat-toggle-icon-wrapper {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.imi-chat-toggle .imi-chat-toggle-icon-wrapper .fa {
  font-size: 30px;
  color: white;
}

.inner-html-wrapper ol ol,
.inner-html-wrapper ul ol {
  list-style-type: lower-roman;
}

.inner-html-wrapper ol, .inner-html-wrapper ul, .inner-html-wrapper li {
  padding-right: 0;
}

.inner-html-wrapper ol ol ol,
.inner-html-wrapper ol ul ol,
.inner-html-wrapper ul ol ol,
.inner-html-wrapper ul ul ol {
  list-style-type: lower-alpha;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .inner-html-wrapper {
    /* IE10+ CSS1.1 here */
  }
}

strong, b {
  font-weight: bold;
}

.text-highlight {
  background: #FEFF03;
  color: #474747;
}

.photoviewer-container-disable-navigation .photoviewer-button-prev, .photoviewer-container-disable-navigation .photoviewer-button-next {
  opacity: 0.5;
  pointer-events: none;
}

.dewa-theme .modal-svelte-menu {
  padding: 4px 0;
  border: 1px solid #80808080;
  list-style: none;
}

.dewa-theme .modal-svelte-menu li {
  padding: 6px 10px;
  font-size: 13px;
  cursor: pointer;
}

.dewa-theme .modal-svelte-menu li:hover {
  background: #80808080;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .inner-html-wrapper {
    /* IE10+ CSS1.1 here */
  }
  .inner-html-wrapper p {
    word-break: break-word;
    word-wrap: break-word;
    width: 100%;
  }
}

.inner-html-wrapper a {
  display: inline-block;
}

.inner-html-wrapper.file {
  overflow: hidden;
  white-space: nowrap;
  width: 163px;
  text-overflow: ellipsis
}

.inner-html-wrapper ul.marked-js-el, .inner-html-wrapper ol.marked-js-el {
  padding-left: 20px;
  margin: 0;
}

.inner-html-wrapper p {
  padding: 0;
  margin: 0;
}

.inner-html-wrapper p:not(:first-child) {
  margin-top: 10px;
}

.chat-body {
  height: 100%;
  overflow-y: auto;
}

[slot="chat-body"] {
  height: 79%;
  width: 100%;
}

.chat-header-slot {
  height: 79%;
  width: 100%;
}

.limit-oneline {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

sup,
sub {
  /*https://stackoverflow.com/questions/35022505/vertically-center-sub-and-super-script-in-html*/
  top: 0;
  bottom: 0;
  line-height: 1;
  font-size: 8px;
}

.line-count-m-p-0 * {
  padding: 0 !important;
  margin: 0 !important;
}


.chat-button {
  position: fixed;
  z-index: 9811 !important;
  right: 20px;
  bottom: 30px;

  visibility: hidden;
  overflow: hidden;

  width: 50px;
  height: 50px;

  text-align: center;

  opacity: 0;
  color: #fff;
  border: none;
  border-radius: 50px;
  background: none;
  background-color: $color-brand;

  &.show {
    visibility: visible;

    opacity: 1;
  }

  @media #{$xs-layout} {
    width: 40px;
    height: 40px;
  }

  & i {
    font-size: 22px;
    line-height: 50px;

    display: block;
    @media #{$xs-layout} {
      font-size: 16px;
      line-height: 40px;
    }
  }

  &:hover {
    & i {
      //animation-name: fadeInUp;
      //animation-duration: 1s;
      //animation-iteration-count: infinite;

      //animation-fill-mode: both;
    }
  }
}
